import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#352C71",
    boxShadow: "0px -3px 25px rgba(0, 0, 0, 0.25)",
    color: "#FFFFFF",
    height: "auto",
    display: "flex",
    alignItems: "flex-end",
  },
  main: {
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(-3),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: theme.spacing(-6),
    },
  },
  warningFooter: {
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg">
        <Box mt={10} mb={5}>
          <Grid container direction="row" alignItems="center" >
            <Grid item xs={12} md={4} lg={4} xl={4} sm={4} align="left">
              <Box className={classes.warningFooter}>
                <Typography style={{ fontWeight: "600" }} variant="h5" gutterBottom >
                  Warning
                    </Typography>
                <Typography variant="subtitle1" >
                  This website is not associated with Plarium
                  and RaidBot may be against their TOS/EULA.
                    </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} xl={4} lg={4} md={4} sm={4} />
            <Grid item xs={12} xl={4} lg={4} md={4} sm={4} align="center">
              <Box className={classes.main}>
                <Typography style={{ fontWeight: "600" }} variant="h5" gutterBottom>
                  Legal information
                </Typography>
                <Typography variant="subtitle1">
                  Botting is not against any law.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box pb={5}>
          <Grid item xs={12} container justify="center" alignItems="flex-end">
            <Typography style={{ fontWeight: "600" }} variant="subtitle1">
              &#169; RaidBot 2021.
            </Typography>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
